<template>
  <div>
    <v-dialog v-model="openDialog" max-width="500" persistent>
      <v-card>
        <v-card-title>
          Nouveau code de parrainage
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6">
          <v-text-field
                  v-model="form.code"
                  :error-messages="formErrors && formErrors.code ? formErrors.code[0] : ''
               "
                  label="Code *"
                  @input="
                formErrors && formErrors.code ? (formErrors.code = '') : false
                "
          />
          <v-textarea v-model="form.description"
                      :error-messages="
                  formErrors && formErrors.description
                    ? formErrors.description[0]
                    : ''
                "
                      label="Description"
                      rows="3"
                      @input="
                  formErrors && formErrors.description
                    ? (formErrors.description = '')
                    : false
                "
          >
          </v-textarea>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="primary"
                 depressed
                 @click="send"
          >
            <v-icon left>mdi-content-save</v-icon>
            Savegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         btnLoading: false,
         formErrors: {},
         form: {
            code: "",
            description: "",
         },
         openDialog: false,
      };
   },
   methods: {
      open() {
         this.openDialog = true;
      },
      close() {
         this.openDialog = false;
      },
      send() {
         this.btnLoading = true;
         HTTP.post("v1/referral-codes/store", this.form)
               .then(() => {
                  this.$successMessage = "Enregistrement avec succès"
                  this.btnLoading = false
                  this.close()
                  this.form = {}
                  this.$emit("refresh")
               })
               .catch((err) => {
                  this.btnLoading = false
                  this.formErrors = err.response.data.errors
               });
      },
   },
   created() {
   },
};
</script>

<style scoped>
.arabic .v-text-field__slot .v-label {
    text-align: right !important;
    direction: rtl;
    left: inherit;
    right: 0 !important;
}
</style>
  