<template>
  <div>
    <v-container fluid>
      <div class="main">

        <div class="text-end mb-3">
          <v-btn color="gifty rounded-lg"
                 depressed
                 dark
                 @click="AddReferralCode">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-card-title>
            <div>
              <v-text-field
                      v-model="filter.keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Rechercher..."
                      rounded
                      single-line
                      v-on:keyup.enter="getItems"
              />
            </div>
          </v-card-title>

          <v-card-text class="pa-0">

            <v-skeleton-loader v-if="isLoading" type="table"/>

            <div v-if="referralCodes.length && !isLoading">

              <v-divider/>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th class="text-center">Utilisateurs</th>
                    <th>Créé le</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in referralCodes" :key="item.id">
                    <td>
                      {{ item.code }}
                    </td>
                    <td>
                      {{ item.description ?? '-' }}
                    </td>
                    <td class="text-center font-weight-medium gifty--text">
                      <v-icon color="gifty">mdi-account-group-outline</v-icon>
                      {{ item.users_count }}
                    </td>
                    <td>
                      {{ item.created_at }}
                    </td>
                    <td>
                      <v-btn icon @click="deleteReferralCode(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                      <v-btn icon @click="UpdateReferralCode(item)">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div v-if="!referralCodes.length && !isLoading">
              <NoResults @reload="getItems" />
            </div>

          </v-card-text>
        </v-card>

        <AddReferralCode ref="AddReferralCode" @refresh="getItems"/>
        <UpdateReferralCode ref="UpdateReferralCode" @refresh="getItems"/>
        <DeleteDialog ref="deleteReferralCode" @confirmed="removeReferralCode"/>

      </div>
    </v-container>
  </div>
</template>

<script>
import AddReferralCode from "./components/AddReferralCode.vue";
import UpdateReferralCode from "./components/UpdateReferralCode.vue";
import DeleteDialog from "./components/DeleteDialog.vue";
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults.vue";

export default {
    components: {NoResults, AddReferralCode, UpdateReferralCode, DeleteDialog},
    data() {
        return {
            delete_id: null,
            filter: {},
            isLoading: false,
            referralCodes: [],
        };
    },
    methods: {
        getItems() {
            this.isLoading = true;
            HTTP.get("v1/referral-codes")
                .then((res) => {
                    this.referralCodes = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        AddReferralCode() {
            this.$refs.AddReferralCode.open();
        },
        UpdateReferralCode(item) {
            this.$refs.UpdateReferralCode.open(item);
        },
        deleteReferralCode(item) {
            this.delete_id = item.id;
            this.$refs.deleteReferralCode.open();
        },
        removeReferralCode() {
            HTTP.delete("v1/referral-codes/delete/" + this.delete_id)
                .then(() => {
                    this.$successMessage = "Ce code de parrainage a été supprimé";
                    this.getItems();
                }).catch((err) => {
                console.log(err);
            });
        },
    },
    created() {
        this.getItems()
    }
};
</script>