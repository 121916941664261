<template>
  <div>
    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="text-center">
        <v-card-text class="pa-6">
          <v-icon class="animate__animated animate__rotateIn" color="primary" size="80">
            mdi-information-outline
          </v-icon>
          <h2 class="text--primary mt-3">Supprimer un code de parrainage</h2>
          <p class="mt-5 mb-5"> Voulez-vous vraiment supprimer ce code parrainage ?</p>
          <v-btn class="rounded-lg mr-2 text-none" large text @click="notConfirmed()">
            <v-icon left>mdi-close</v-icon>
            Annuler
          </v-btn>
          <v-btn class="rounded-lg text-none" color="primary" depressed large
                 @click="confirm()">
            <v-icon left>mdi-check-circle-outline</v-icon>
            Confirmer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            is_confirm: false,
            confirm_dialog: false
        }
    },
    methods: {
        open() {
            this.dialog = true
        },
        confirm() {
            this.$emit('confirmed')
            this.dialog = false;
        },
        notConfirmed() {
            this.dialog = false;
        }
    }
}
</script>